import styled, { css } from 'styled-components';
export * from './common.css';

export const Section = styled.section`
  background-color: ${({customBackgroundColor}) =>
    customBackgroundColor || '#f2f4fa'
  };
  --py: 36px;
  padding: var(--py) 0;
  @media (min-width: 24em) {
    --py: 48px;
  }
  @media (min-width: 36em) {
    --py: 60px;
  }
  @media (min-width: 48em) {
    --py: 72px;
  }
`

export const wrap = css`
  margin: 0 auto;
  max-width: 1200px;
`;

export const grid = css`
  display: grid;
  grid-gap: var(--gap-y) var(--gap-x);
  grid-template-rows: max-content;
  grid-template-columns: repeat(var(--cols, 1), auto);
  justify-content: center;
  justify-items: center;
  text-align: center;
  --gap-y: 24px;
  --gap-x: 24px;
  @media (min-width: 24em) {
    --gap-y: 36px;
    --gap-x: 36px;
  }
  @media (min-width: 36em) {
    --gap-y: 48px;
    --gap-x: 48px;
  }
  @media (min-width: 48em) {
    justify-content: start;
    text-align: left;
    --cols: 2;
  }
  @media (min-width: 60em) {
    --cols: 3;
  }
`;

export const imageCell = css`
  width: 240px;
  align-self: end;
  @media (min-width: 36em) {
  }
  @media (min-width: 48em) {
    width: 240px;
  }
  @media (min-width: 60em) {
    width: 300px;
  }
`;

export const textCell = css`
  @media (min-width: 48em) {
    padding: var(--py) 0;
    order: 1;
  }
`;

export const heading = css`
  font-size: 30px;
  margin: 0;
  margin-top: 1rem;
`;

export const content = css`
  margin: 0;
  margin-top: 1rem;
`;
