import React from 'react';
import { Button } from 'src/components/atoms/Button';
import { FluidImg } from 'src/embeds/image/image-dato';
import * as css from './drawer-pdf-download.css';
import * as sys from '../../system';

export default function DrawerPdfDownload({
  callouts,
  customData,
  preHeading,
  heading,
  image,
  content,
  contentNode
}) {
  customData = JSON.parse(customData);
  const { pdfLink, cta } = callouts.find(
    (callout) => callout.__typename == 'DatoCmsPdfBlock',
  );

  return (
    <css.Section {...customData?.flags}>
      <div css={[css.grid, sys.frame, css.wrap]}>
        <div css={[css.textCell]}>
          {preHeading && (
            <p className="f-mono" css={[css.metaHeading, css.hilite]}>
              {preHeading}
            </p>
          )}
          {heading && <h3 css={[css.heading]}>{heading}</h3>}
          {content ? (
            <div
              css={[css.content]}
              dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}
            />
          ) : null}
          {pdfLink
            && cta
            && <Button to={pdfLink} rel="noopener noreferrer" target="_blank">{cta}</Button>}
        </div>
        {image && (
          <div css={[css.imageCell]}>
            <FluidImg data={image} />
          </div>
        )}
      </div>
    </css.Section>
  );
}
